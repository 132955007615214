<template>
  <div
    class="
      w-full cursor-pointer
      border-b border-gray-100 last:border-b-0 py-12 md:py-24 px-4
      transition duration-200 even:bg-blue-50
      "
  >
    <div class="flex items-center">
      <div class="flex flex-col overflow-hidden">
        <div class="w-full text-center">
          <img
            :src="post.thumbnailUrl"
            class="max-h-256 mx-auto"
          >
        </div>

        <p class="font-semibold text-14 md:text-18 mb-4 leading-tight whitespace-normal mt-16">
          {{ post.title }}
        </p>
        <p class="text-gray-700 text-12 md:text-base leading-tight whitespace-normal mt-16">
          {{ post.content }}
        </p>
      </div>
    </div>

    <div class="flex justify-center mt-16">
      <AppButton
        variant="primary"
        size="standart-sm"
        class="mr-8"
        @click="onActionButtonClick('onEditClick')"
      >
        Edit
        <i class="icon-edit-pencil hidden md:block text-12 ml-4" />
      </AppButton>
      <AppButton
        variant="danger"
        size="standart-sm"
        @click="onActionButtonClick('onDeleteClick')"
      >
        Delete
        <i class="icon-bin hidden md:block text-12 ml-4" />
      </AppButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'PostItemRow',

  components: {
    AppButton
  },

  props: {
    post: {
      required: true,
      type: Object
    }
  },

  emits: ['onEditClick', 'onDeleteClick'],

  setup (props, { emit }) {
    const onActionButtonClick = (value) => {
      emit(value, props.post)
    }

    return {
      onActionButtonClick
    }
  }
})
</script>
