<template>
  <AppModal
    :modelValue="modelValue"
    :max-width="500"
    @onModalClose="onCancelClick"
  >
    <div class="w-full">
      <p class="font-semibold text-lg md:text-xl whitespace-normal pr-16">
        <slot name="modal-title" />
      </p>

      <div class="flex justify-end items-center mt-24">
        <AppButton
          variant="primary"
          size="standart-sm"
          class="mr-8"
          @click="onConfirmClick"
        >
          <slot name="modal-button-confirm">
            Confirm
          </slot>
        </AppButton>
        <AppButton
          variant="danger"
          size="standart-sm"
          @click="onCancelClick"
        >
          <slot name="modal-button-confirm">
            Cancel
          </slot>
        </AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { defineComponent } from 'vue'

import AppButton from '@/components/stateless/AppButton.vue'

import AppModal from './AppModal.vue'

export default defineComponent({
  name: 'AppConfirmModal',

  components: {
    AppButton,

    AppModal
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue', 'onConfirmClick', 'onCancelClick'],

  setup (props, { emit }) {
    const onConfirmClick = () => {
      emit('onConfirmClick')
    }

    const onCancelClick = () => {
      emit('update:modelValue', false)
      emit('onCancelClick')
    }

    return {
      onConfirmClick,
      onCancelClick
    }
  }
})
</script>
