<template>
  <transition
    enter-active-class="transition duration-200 material-easing"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transform duration-100 transition material-easing"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="modelValue"
      class="w-full h-full fixed inset-0 flex justify-center items-center backdrop-bg z-20"
    >
      <transition
        enter-active-class="transform duration-200 delay-100 transition material-easing"
        enter-class="opacity-0 translate-y-10"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transform duration-100 transition material-easing"
        leave-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-10"
      >
        <div
          v-show="modelValue"
          class="
            w-full relative bg-white rounded-8 shadow-md border
          border-blueGray-100 m-12 px-12 py-16 md:px-224 md:py-32
          "
          :style="{ maxWidth: `${maxWidth}px` }"
        >
          <div
            class="absolute right-0 top-0 mr-12 mt-8 cursor-pointer"
            @click="onCrossClickHandler"
          >
            <i class="icon-cross text-12 text-gray-400" />
          </div>

          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddModal',

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 400
    }
  },

  emits: ['onModalClose'],

  setup (props, { emit }) {
    const onCrossClickHandler = () => {
      emit('onModalClose')
    }

    return {
      onCrossClickHandler
    }
  }
})
</script>

<style lang="scss" scoped>
  .backdrop-bg {
    background-color: rgba(0, 0, 0, .8);
  }
</style>
