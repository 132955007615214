<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <AppEmptyPage v-if="!posts.length">
      <template #title>You have not added any posts yet</template>
      <template #action>
        <AppButton
          :to="{ name: routeNames.addPost }"
          variant="primary"
          size="standart-sm"
        >
          Add
          <i class="icon-plus text-12 ml-8" />
        </AppButton>
      </template>
    </AppEmptyPage>

    <template v-else>
      <!-- Header -->
      <div class="flex justify-between items-center pb-8 border-b-2 border-gray-200">
        <h3 class="text-base md:text-18 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
          Posts
        </h3>

        <!-- Action buttons -->
        <div class="flex-shrink-0">
          <AppButton
            :to="{ name: routeNames.addPost }"
            variant="primary"
            size="standart-sm"
          >
            Add
            <i class="icon-plus text-12 ml-8" />
          </AppButton>
        </div>
      </div>

      <div
        ref="scrollContainer"
        class="w-full flex flex-1 flex-col overflow-y-auto"
      >
        <PostItemRow
          v-for="post in posts"
          :key="post.id"
          :post="post"
          @onEditClick="onEditClickHandler"
          @onDeleteClick="onDeleteClickHandler"
        />

        <AppPagination
          class="py-16"
          :current-page="page"
          :total="total"
          @updateCurrentPage="onUpdatePageHandler"
        />
      </div>
    </template>

    <AppConfirmModal
      v-model="deletePostConfirmModal"
      @onConfirmClick="onDeleteConfirmHandler"
      @onCancelClick="onDeleteCancelHandler"
    >
      <template #modal-title>
        Are you sure you want to delete - {{ selectedPostToDelete.title }}
      </template>
    </AppConfirmModal>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { usePosts, usePagination } from '@/core/compositions'
import { notification } from '@/core/helpers'
import { routeNames } from '@/router'

import AppEmptyPage from '@/components/stateless/layout/AppEmptyPage.vue'
import AppButton from '@/components/stateless/AppButton.vue'
import AppConfirmModal from '@/components/stateless/modals/AppConfirmModal.vue'
import AppPagination from '@/components/stateless/AppPagination.vue'

import PostItemRow from './components/PostItemRow.vue'

export default defineComponent({
  name: 'Resources',

  components: {
    AppEmptyPage,
    AppButton,
    AppConfirmModal,
    AppPagination,

    PostItemRow
  },

  setup () {
    const router = useRouter()
    const { posts, getPosts, deletePost } = usePosts()
    const {
      page,
      total,
      size,
      onUpdatePage,
      onUpdateTotal,
      setPaginationFromQuery
    } = usePagination(routeNames.posts)

    const loading = ref(true)
    const deletePostConfirmModal = ref(false)
    const selectedPostToDelete = ref(null)

    const scrollContainer = ref()

    onMounted(() => {
      setPaginationFromQuery()
      getInitialData()
    })

    const getInitialData = async () => {
      loading.value = true

      try {
        const result = await getPosts({
          page: page.value,
          size: size.value,
          options: {
            isPublished: false,
            isDeleted: false
          }
        })

        onUpdateTotal(result.count)
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const deletePostHandler = async (postId) => {
      loading.value = true

      try {
        await deletePost(postId)

        getInitialData()
      } catch (e) {
        console.log(e)
        loading.value = false
        notification()
      }
    }

    const onEditClickHandler = (post) => {
      router.push({ name: routeNames.editPost, params: { id: post.id } })
    }

    const onDeleteClickHandler = (resource) => {
      selectedPostToDelete.value = resource
      deletePostConfirmModal.value = true
    }

    const onDeleteConfirmHandler = () => {
      deletePostConfirmModal.value = false
      deletePostHandler(selectedPostToDelete.value.id)
      selectedPostToDelete.value = null
    }

    const onDeleteCancelHandler = () => {
      selectedPostToDelete.value = null
      deletePostConfirmModal.value = false
    }

    const onUpdatePageHandler = (page) => {
      onUpdatePage(page)

      getInitialData()

      scrollContainer.value.scrollTop = 0
    }

    return {
      routeNames,
      loading,
      posts,
      deletePostConfirmModal,
      onDeleteConfirmHandler,
      onDeleteCancelHandler,
      selectedPostToDelete,
      onEditClickHandler,
      onDeleteClickHandler,

      // Pagination
      page,
      total,
      onUpdatePageHandler,

      scrollContainer
    }
  }
})
</script>
